import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Footer, NavBar } from './Components';
import { ContactPage, ProjectsPage, HomePage } from './Pages';
import './App.css';


function App() {
  return (
    <div className="App">
      <div className='app-wrapper'>
        <NavBar />
        <div className='content-wrapper'>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/projects' element={<ProjectsPage />} />
            <Route path='/contact' element={<ContactPage />} />
          </Routes>
        </div>
        <Footer />
      </div>

    </div>
  );
}

export default App;