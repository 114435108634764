import React, { useState, useEffect } from 'react';
import { resume, file1 } from '../../Assets';

import './aboutme.css';

const AboutMe = () => {
    return (
        <div>
            <section id="about" className='about-section'>
                <div className='profile-photo-container'>
                    <img src={file1} alt='Profile Picture' className='profile-photo' />
                </div>
                <div className='about-text'>
                    <h2>About Me</h2>
                    <p>
                        Hello! I am Carl Boyles,
                        a full-stack developer with a knack for assembling together React websites and C# applications.
                        My motto is customer focused! I always seek to improve any products I work on to increase usability.
                        I am committed to providing users the best experience!
                    </p>
                    <a href={resume} className='resume-button' download>
                        Get My Resume
                    </a>
                </div>
            </section>
            <section id="about-my" className='about-my-section'>
                <div className='about-my-content'>
                    <h2>About My</h2>
                    <div className='line-break'></div>
                    <div className='past-present'>
                        <div className='past'>
                            <h3>Past</h3>
                            <p>
                                I discovered programming in high school and found it challenging.
                                I almost did not pursue it, however, figuring out how to run code,
                                besting taxing errors, and navigating difficult coding languages, I
                                had found fulfillment in solving difficult coding problems.
                            </p>
                            <blockquote>
                                "Confusion is part of programming" - Felienne Hermans
                            </blockquote>
                        </div>
                        <div className='present'>
                            <h3>Present</h3>
                            <p>
                                Now, I work diligently on improving my coding skills to be a better devops engineer.
                                I'm also employed as a tax software developer, deciphering complex tax laws into usable
                                software for our users to get the best refund possible.
                            </p>
                            <blockquote>
                                “Web development is difficult, only then it is fun to do. You just have to set your standards. If it were to be easy, would anyone do it?”
                                ― Olawale Daniel
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AboutMe
