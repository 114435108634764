import React from 'react';
import { AboutMe, Skills } from '../../Components';

const HomePage = () => {
    return (
        <div>
            <AboutMe />
            <Skills />
        </div>
    )
}

export default HomePage
