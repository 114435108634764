import React from 'react';
import './footer.css';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const Footer = () => {
    return (
        <div className='footer-section'>
            <div className='footer-container'>
                <p>&copy; 2024 Carl Boyles. All rights reserved.</p>
                <div className='social-icons'>
                    <a href='https://www.linkedin.com/in/carl-boyles-b90312122' target='_blank' rel='noopener noreferrer'><FaLinkedin /></a>
                    <a href='https://github.com/cboyles17' target='_blank' rel='noopener noreferrer'><FaGithub /></a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
