import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './contactme.css';

const ContactMe = () => {
    const [formData, setFormData] = useState({
        name: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_sw7w6o3',
            'template_j0sbr73',
            formData,
            'h5Us9RMpZnXqJOPdf'
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message sent successfully!');
                setFormData({ name: '', email: '', message: '' }); // Reset form fields
            })
            .catch((error) => {
                console.error('FAILED...', error);
                alert('Failed to send message. Please try again later.');
            });
    };

    return (
        <div id="contact" className="contact-section">
            <h2>Contact Me</h2>
            <div className="contact-options">
                <a href="www.linkedin.com/in/carl-boyles-b90312122" target="_blank" rel="noopener noreferrer" className="contact-link">LinkedIn</a>
                <a href="mailto:red2_30boyles@yahoo.com" className="contact-link">Email</a>
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <label htmlFor="message">Message</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                ></textarea>
                <button type="submit">Send Message</button>
            </form>
        </div>
    );
}

export default ContactMe;
