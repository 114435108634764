import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
    const [isMobile, setIsMobile] = useState(false);

    const handleToggle = () => {
        setIsMobile(!isMobile);
        document.body.classList.toggle('menu-open', !isMobile);
    };

    const closeMenu = () => {
        setIsMobile(false);
        document.body.classList.remove('menu-open');
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                closeMenu();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <nav className='navbar'>
                <div className='navbar-logo'>
                    <NavLink to="/" exact className="active-name" >Carl Boyles</NavLink>
                </div>
                <ul className={isMobile ? "nav-links nav-links-mobile" : "nav-links"} onClick={closeMenu}>
                    <li><NavLink to="/" exact className="active">Home</NavLink></li>
                    <li><NavLink to="/projects" className="active">Projects</NavLink></li>
                    <li><NavLink to="/contact" className="active">Contact Me</NavLink></li>
                </ul>
                <div className="mobile-menu-icon" onClick={handleToggle}>
                    {isMobile ? <FaTimes /> : <FaBars />}
                </div>
            </nav>
        </div>
    )
}

export default Navbar;