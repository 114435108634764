import React from 'react';
import './projects.css';
import './../../index.css';
import { comingsoon, underconstruction } from '../../Assets';

const projects = [
    {
        title: 'Project One',
        description: 'Placeholder',
        imageUrl: comingsoon,
        github: '',
        demo: ''
    },
    {
        title: 'Project Two',
        description: 'Placeholder',
        imageUrl: underconstruction,
        github: '',
        demo: ''
    },
    {
        title: 'Project Three',
        description: 'Placeholder',
        imageUrl: underconstruction,
        github: '',
        demo: ''
    },
    //update to use sanity.io
];

const Projects = () => {
    return (
        <div id='projects' className='projects-section'>
            <h2>Projects</h2>
            <div className='projects-container'>{projects.map((project, index) => (
                <div key={index} className='project-card'>
                    <h3>{project.title}</h3>
                    <img src={project.imageUrl} alt={project.title} className='project-image' />
                    <p>{project.description}</p>
                    <div className='project-links'>
                        <a href={project.github} target='_blank' rel='noopener noreferrer'>GitHub</a>
                        <a href={project.demo} target='_blank' rel='noopener noreferrer'>Live Demo</a>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );
}

export default Projects;
