import React from 'react'
import { Projects } from './../../Components';

const ProjectsPage = () => {
    return (
        <div>
            <Projects />
        </div>
    )
}

export default ProjectsPage
