import React from 'react';
import './skills.css';
import './../../index.css';
import {
    FaReact, FaHtml5, FaCss3Alt, FaNode, FaGit,
    FaGithub, FaJava, FaDocker, FaPython, FaJsSquare
} from 'react-icons/fa';
import { TbBrandCSharp, TbSql } from "react-icons/tb";
import { SiTerraform, SiMysql, SiVisualstudio, SiVisualstudiocode } from "react-icons/si";

const Skills = () => {
    const skills = [
        { name: 'React', icon: <FaReact /> },
        { name: 'HTML5', icon: <FaHtml5 /> },
        { name: 'CSS3', icon: <FaCss3Alt /> },
        { name: 'Javascript', icon: <FaJsSquare /> },
        { name: 'Node.js', icon: <FaNode /> },
        { name: 'Git', icon: <FaGit /> },
        { name: 'GitHub', icon: <FaGithub /> },
        { name: 'Java', icon: <FaJava /> },
        { name: 'Docker', icon: <FaDocker /> },
        { name: 'Python', icon: <FaPython /> },
        { name: 'C#', icon: <TbBrandCSharp /> },
        { name: 'SQL', icon: <TbSql /> },
        { name: 'Terraform', icon: <SiTerraform /> },
        { name: 'MySql', icon: <SiMysql /> },
        { name: 'Visual Studio', icon: <SiVisualstudio /> },
        { name: 'Visual Studio Code', icon: <SiVisualstudiocode /> }
    ]
    return (
        <div id="skills" className='skills-section'>
            <h2>Skills</h2>
            <div className='skills-container'>
                {skills.map((skill, index) =>
                (<div key={index} className='skill-card'>
                    <div className='skill-icon'>{skill.icon}</div>
                    <p>{skill.name}</p>
                </div>
                ))}
            </div>
        </div>
    );
};

export default Skills;
